

  /* .rdw-suggestion-option {
    white-space: nowrap !important;
    width: max-content;
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
  } */

  .blueText {
    color: blue;
  }

  /* .rdw-suggestion-dropdown {

    position: relative;
    align-items: start;
    width:min-content !important;
    border-radius: 2px !important;
    border-color: #4C33FF !important;
    border-width: 4px !important;
    z-index: 99999999 !important;
    } */
    
  
/* .rdw-suggestion-dropdown {

position: relative;
align-items: start;
width:min-content !important;
border-radius: 2px !important;
border-color: #4C33FF !important;
border-width: 4px !important;

cursor: not-allowed !important;
  
} */

.rdw-suggestion-option {
  white-space: nowrap !important;
  width: 100% !important;
  padding: 5px 5px !important;
  border-bottom: 1px solid #f1f1f1 !important;
  z-index: 10 !important;
  cursor: not-allowed !important;
  background-color: rgb(248, 248, 248) !important;
  color: #4C33FF !important;
  pointer-events: none !important;
  position: relative !important;
}

.rdw-suggestion-dropdown {
  position: absolute !important;
  top: calc(100% + 5px) !important; 
  left: 0 !important; 
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; 
  border-radius: 4px !important; 
  border: 1px solid #4C33FF !important;
  padding: 5px !important; 
  max-height: 200px !important; 
  z-index: -1 !important;
  line-height: 0.9 !important;
  overflow-y: visible !important; 
  /* pointer-events: none !important; */
  min-height: 100px !important;
  font-size: 12px!important;
}

/* .rdw-mention-link{
color: black!important;
background-color: white !important;
} */

.rdw-suggestion-option-active {
    background-color: #f0fbff !important;
    color: #4C33FF !important;
    font-weight: bold  !important;
  }

  .rdw-suggestion-wrapper {
    z-index: 100000 !important; 
  }

  .wrapper-class-section{
   height: '';
  }


  .wrapper-class-section-active {
    height: calc(100% + 150px) !important;
  }

  /* .rdw-editor-wrapper{
    
  } */
.DraftEditor-editorContainer{
  width: 100%;
  height:100%  !important;

}

.DraftEditor-root{
  width: 100%; 
  
  /* overflow-y: auto; */
}

.EditorPositionsright .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: right !important;
  /* height:100%; */
  margin-top: 0px !important;
 
}

.EditorPositionsleft .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: left !important;
  /* height:100%; */
  margin-top: 0px !important;

}

.EditorPositionscenter .public-DraftStyleDefault-ltr{

  direction: ltr !important;
  text-align: center !important;
  height:100%;
  margin-top: 0px !important;

  /* width: 100%; */
}

.EditorPositionsjustify .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: justify !important;
  height:100%;
  margin-top: 0px !important;
}

/* .public-DraftStyleDefault-ul{
  display: flex;
  flex-direction: column;
  align-items:center  ;
}
 */

 .EditorPositionsleft .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: left !important;
  text-align: left !important;
}

.EditorPositionscenter .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

  .EditorPositionscenter .public-DraftEditorPlaceholder-root {
  padding-left: 35% !important;
}


.EditorPositionsright .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
}

.EditorPositionsjustify .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: justify !important;
 
}


.EditorPositionscenter .public-DraftStyleDefault-ol {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}

.EditorPositionsright .public-DraftStyleDefault-ol {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
}

.EditorPositionsjustify .public-DraftStyleDefault-ol {
  display: flex !important;
  flex-direction: column !important;
  /* align-items: justify !important;*/
   align-items: left !important;
}

h1 {
  font-weight: normal !important; 
  font-size: 1.5em !important;
}

h2 {
  font-weight: normal !important; 
}

h3 {
  font-weight: normal !important; 
}

h4 {
  font-weight: normal !important; 
}

h5 {
  font-weight: normal !important; 
}

h6 {
  font-weight: normal !important; 
}

.bold-text {
  font-weight: bold !important;
  /* Other styles */
}

.normal-text {
  font-weight: normal;
  /* Other styles */
}



.undo-button {
  position: absolute;
  top: 3px;
  right: 7px;
  transition: none; /* Remove transition on button press */
  margin: 0;
  padding: 0;
  width: fit-content;
}