.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background: #000000;
}

.rdw-suggestion-option {
  white-space: nowrap !important;
  width: max-content;
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.table-cell {
  border: 1px solid #BBBBBB;
  height: 4em;
}

.css-1p3c7zo-MuiPaper-root-MuiAppBar-root {
  padding-right: 0px !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}