/* .rdw-suggestion-option {
    white-space: nowrap !important;
    width: min-content !important;

    border-bottom: 1px solid #f1f1f1 !important;
    z-index: 99999999 !important;
    
   
  } */

  /* .rdw-suggestion-option {
    white-space: nowrap !important;
    width: max-content;
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
  } */

  .blueText {
    color: blue;
  }
  
/* .rdw-suggestion-dropdown {
position: relative;
align-items: start;
width:min-content !important;
border-radius: 2px !important;
border-color: #4C33FF !important;
border-width: 4px !important;
z-index: 99999999 !important;
} */

/* .rdw-mention-link{

color: black!important;
background-color: white !important;

} */

/* .rdw-suggestion-option-active {
    background-color: #f0e9e9 !important;
    z-index: 99999999 !important;
    width:min-content !important;
  } */


  /* .rdw-suggestion-wrapper {
    z-index: 2; 
    display: flex;
    position: absolute;
    background-color: blue;
  } */
/* .DraftEditor-editorContainer{
  width: 100%;
  height:min-content;

} */

.DraftEditor-root{
  width: 100%; 
  /* overflow-y: auto; */
}

.EditorPosition-table-right .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: right !important;
  /* height:min-content; */
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.EditorPosition-table-left .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: left !important;
  /* height:min-content; */
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.EditorPosition-table-center .public-DraftStyleDefault-ltr{

  direction: ltr !important;
  text-align: center !important;
  /* height:min-content; */
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.EditorPosition-table-justify .public-DraftStyleDefault-ltr {
  direction: ltr !important;
  text-align: justify !important;
  /* height:min-content; */
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;

}

 .EditorPosition-table-left .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: left !important;
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.EditorPosition-table-center .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* height:min-content; */
}



.EditorPosition-table-right .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  /* height:min-content; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin: 6px !important ;
}

.EditorPosition-table-justify .public-DraftStyleDefault-ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: justify !important;
  /* height:min-content; */

  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}


.EditorPosition-table-center .public-DraftStyleDefault-ol {
  display: flex;
  flex-direction: column !important;
  /* height:min-content; */
  align-items: center !important;
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
} 

 .EditorPosition-table-right .public-DraftStyleDefault-ol {
  display: flex !important;
  flex-direction: column !important;
  /* height:min-content; */
  align-items: flex-end !important;
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.EditorPosition-table-justify .public-DraftStyleDefault-ol {
  display: flex !important;
  flex-direction: column !important;
  align-items: justify !important;
  /* height:min-content; */
  margin: 6px !important ;
  margin-left: 0px !important;
  margin-right: 0px !important;
} 


h1 {
  font-weight: normal !important; 
  
}

 h2 {
  font-weight: normal !important; 
}

