.customModalStyle{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .paperStyle {
    width: 50% !important;
    height: auto !important;
    background-color: white !important;
    padding: 16px !important; /* You can adjust the padding as needed */
    padding-right: 16px !important;
    border-radius: 3px !important; /* You can adjust the border radius as needed */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* You can adjust the shadow as needed */
  }
  .headerStyle {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px; /* You can adjust the margin as needed */
  }
  .iconStyle {
    margin-right: 8px !important; /* You can adjust the margin as needed */
    font-size: 2.0rem !important;
    color: #4C33FF !important; /* You can adjust the color as needed */
  }
  .closeButtonStyle {
    margin-left: auto !important; /* You can adjust the margin as needed */
    padding: 0 !important;
  }
  .pendingSection {
    border-color: green !important;
    margin-bottom: 1px !important;
    border-radius: 0.5px !important;
    padding: 0.5px !important;
    border-width: 1px !important;
    background-color: green !important;
  }
  .approvedSection {
    border-color: #7284A3 !important;
    margin-bottom: 1px !important;
    border-radius: 0.5px !important;
    padding: 0.5px !important;
    border-width: 1px !important;
    transition: box-shadow 0.3s ease;
  }
  .divButtons {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: auto !important;
    padding-top: 30px !important;
  }
  