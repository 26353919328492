.gs-main {
    min-width: 50vw !important;
    min-height: 200vh !important;
}

.gs-cell-rendered {
    font: inherit !important;
    font-size: inherit !important;
    text-decoration: "inherit" !important
        /* width: inherit !important;
    height: inherit !important;
    text-align: inherit !important; */
}

/* .Spreadsheet__cell {

    background-color: lightgray;
    border: 1px solid black;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    white-space: normal;
} */