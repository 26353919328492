.detail-card {
    border: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .detail-card:hover {
    border-color: #4C33FF;
  }
  
  .detail-card:hover::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    border-radius: 10px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .detail-card:hover::before {
    border-color: #4C33FF;
  }
  
  .plans-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .plans-table th,
  .plans-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .subscribed-plan {
    background-color: #e3f2fd; /* Example background color for subscribed plan */
  }
  
  .price-cell,
  .credits-cell {
    background-color: #4c33ff;
    color: white;
  }
  